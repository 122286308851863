import axios from "axios";
import qs from "qs";
import proxy from "@/api/proxy.js";

// 创建取消请求
const CancelToken = axios.CancelToken
const source  = CancelToken.source()
var cancelList = []
var removePending = () =>{
    if(cancelList && cancelList.length){
        cancelList.map(item=>{
            if(item.source){
                item.source.cancel()
            }
        })
    }
}

export function get(url, params = {},headers={"Content-Type": "application/json"}) {
    return new Promise((resolve, reject) => {
        axios.get(
            url,
            {
                headers:headers,
                params:params
            })
            .then((response) => {
                if(self!=top){//框架内的调用
                    if(response&&response.data){

                        var loginInfo={login:true,right:true,msg:"没有登录"};//现在系统没有login和right的传值，先设为true，之后需改为false//************* */

                        if(response.data.login){
                            loginInfo.login=true;
                            loginInfo.msg="已登录";
                        }
                        if(response.data.right){
                            loginInfo.right=true;
                        }
                        let url = window.location.href;
                        let link = url.substring(url.lastIndexOf("#/")).replace('#','');
                        try{
                            parent.vue.portalCheckRight(loginInfo,link);
                        }catch{}

                    }
                }
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
export function post(url, data = {},headers={"Content-Type": "application/x-www-form-urlencoded"}) {
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: url,
            headers: headers,
            data: headers['Content-Type'] == 'application/x-www-form-urlencoded' ? qs.stringify(data) : data,
        }).then(
            (response) => {
                if(self!=top){//框架内的调用
                    if(response&&response.data){

                        var loginInfo={login:true,right:true,msg:"没有登录"};//现在系统没有login和right的传值，先设为true，之后需改为false//************* */

                        if(response.data.login){
                            loginInfo.login=true;
                            loginInfo.msg="已登录";
                        }
                        if(response.data.right){
                            loginInfo.right=true;
                        }
                        let url = window.location.href;
                        let link = url.substring(url.lastIndexOf("#/")).replace('#','');
                        try{
                            parent.vue.portalCheckRight(loginInfo,link);
                        }catch{}

                    }
                }
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

// axios默认配置
//axios.defaults.timeout = 100000; // 超时时间

axios.interceptors.request.use(
     (config) => {
        // console.log(process.env.VUE_APP_FLAG);
        // console.log(process.env.NODE_ENV);
        if(process.env.VUE_APP_FLAG!="test"){
            var target=config.url.substring(0,config.url.indexOf("/",1));
            // console.log("target="+target);
            if(target.indexOf("/")==0&&target!=null&&target!=""){
                var domain=proxy[target];
                domain=domain!=null?domain.target:null;
                if(domain!=null) config.url=domain+config.url.substring(config.url.indexOf("/",1));
                //  console.log("config.url="+config.url);
            }
        }
        // 判断是否是获取验证码接口，是就让他跑，不是就标记为取消
        if(config.url && config.url.indexOf('captchaImage') == -1){
            config.cancelToken = source.token
            cancelList.push(config.cancelToken)
        }
        // let openId=window.sessionStorage.getItem("openId");
        // if (openId!=null&&openId!=undefined&&openId!="undefined") {
        //     config.headers.openId = openId;
        // }
        // let custGlobalId=window.sessionStorage.getItem("custGlobalId");
        // if (custGlobalId!=null&&custGlobalId!=undefined&&custGlobalId!="undefined") {
        //     config.headers.custGlobalId = custGlobalId;
        // }
        let token=window.sessionStorage.getItem("accessToken");
        if (token!=null) {
         config.headers.Authorization = token;
        }
        //console.log("------------"+openId+"------------"+token);
        if(config.method=="get") config.data=true;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axios.interceptors.response.use(response =>{
    // 拦截响应，统一处理
    try{
        if(response && response.data && response.data.code){
            switch(response.data.code){
                // 如果是登录过期或者是被人踢下线
                case 600:case 601:case 602:case "600":case "601":case "602":
                    window.sessionStorage.removeItem("custGlobalId");
                    window.sessionStorage.removeItem("accessToken");
                    window.sessionStorage.removeItem("openId");
                    window.sessionStorage.removeItem('userInfo')
                    window.sessionStorage.removeItem('systemList')
                    window.sessionStorage.removeItem('userDetail')
                    // 先中断除了获取验证码的请求
                    removePending()
                    // cancelList = []
                    var loginInfo={login:false,right:false,msg:"登录过期，请重新登录！"};
                    let url = window.location.href;
                    let link = url.substring(url.lastIndexOf("#/")).replace('#','');
                    parent.vue.portalCheckRight(loginInfo,link);
                break;
                // 密码需要修改报错处理
                case "PASSWD_IS_OVERDUE" : 
                    parent.vue.whyOpenMpdal()
                break;
            }
            return response        
        }else{
            return response
        }
    }catch(err){
        return Promise.reject(err)
    }
    
})
