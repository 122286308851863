var domain = function (param) {
  if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'testbuild') {
    // return param.test;
    return param.prod;
  }
  if (process.env.NODE_ENV == 'dev') {
    return param.dev;
  }
  if (process.env.NODE_ENV == 'production') {
    return param.prod;
  }
}

module.exports = {
  "/gx": {
    target: domain({
      // dev: "http://suiyue2.test.liefengtech.com:83/org/org",//dev
      // test: "http://api.org.test.gongxiangplat.com/org",//测试
      // prod: "https://api.org.prod.gongxiangplat.com/org",//生产
      dev: "http://liefeng-gateway.test.gongxiangplat.com/org",//dev
      test: "http://liefeng-gateway.test.gongxiangplat.com/org",//测试
      prod: "https://api.gateway.prod.liefengtech.com/apps/org/org",//生产
    }), //

    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/gx": "/",//重写地址
    },
  },
  "/auth": {
    target: domain({
      // dev: "http://suiyue2.test.liefengtech.com:83/lf-auth",//dev
      // test: "http://gateway-apps.test.gongxiangplat.com/lf-auth",//测试
      // prod: "http://gateway-apps.prod.gongxiangplat.com/lf-auth",//生产
      dev: "http://liefeng-gateway.test.gongxiangplat.com",//dev
      test: "http://liefeng-gateway.test.gongxiangplat.com",//测试
      prod: "https://api.gateway.prod.liefengtech.com",//生产
    }), //

    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/auth": "/",//重写地址
    },
  },
  "/oldpeople": {
    target: domain({
      // dev: "http://120.78.185.255:20032",
      // test: "http://120.78.185.255:20032",
      // dev: "http://412134t2i4.wicp.vip:8010",
      // dev: "http://suiyueweb.commontech.xyz:81",
      // dev: "http://suiyue.web.commontech.xyz:81",
      // test: "http://412134t2i4.wicp.vip:8002",
      // test: "http://suiyueweb.commontech.xyz:81",
      // test: "http://www.mengcc.huhdcc.top:8002",
      // test: "http://suiyue.web.commontech.xyz:81",
      dev: "http://suiyue.web.gongxiangplat.com:18132",
      test: "http://suiyue.web.gongxiangplat.com:18132",
      prod: "https://suiyue.liefengtech.com",
    }), //
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置

    pathRewrite: {
      "^/oldpeople": "/", // 这种接口配置出来
    },
  },
  "/old": {
    target: domain({
      dev: "http://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople",
      test: "http://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople",
      prod: "https://api.gateway.prod.liefengtech.com/apps/oldpeople",
    }), //
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置

    pathRewrite: {
      "^/old": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/syaa": {
    target: domain({
      dev: "http://liefeng-gateway.test.gongxiangplat.com/sy-user/sy-user",
      test: "http://liefeng-gateway.test.gongxiangplat.com/sy-user/sy-user",
      prod: "https://api.gateway.prod.liefengtech.com/sy-user/sy-user",
    }),
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置

    // secure: false, // https协议才设置
    pathRewrite: {
      "^/syaa": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/sendmsg": {
    target: domain({
      dev: "http://liefeng-gateway.test.gongxiangplat.com/chat",
      test: "http://liefeng-gateway.test.gongxiangplat.com/chat",
      prod: "https://api.gateway.prod.liefengtech.com/apps/sy-chat/chat",
    }), // 测试
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/sendmsg": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/verifyapi": {
    target: domain({
      dev: "https://liefeng-gateway.test.gongxiangplat.com",
      test: "https://liefeng-gateway.test.gongxiangplat.com",
      prod: "https://api.gateway.prod.liefengtech.com",
    }), // 测试
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/verifyapi": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/syshequ": {   // 404
    target: domain({
      // dev: "http://suiyue2.test.liefengtech.com:83/sy-top",
      // test: "http://top.api.dev.liefengtech.com",
      // prod: "https://api.sytop.prod.liefengtech.com",
      dev: "https://liefeng-gateway.test.gongxiangplat.com",
      test: "https://liefeng-gateway.test.gongxiangplat.com",
      prod: "https://api.gateway.prod.liefengtech.com",
    }), // 测试
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/syshequ": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/baseparty": {
    target: domain({
      // dev: "http://test.property.dubbo.liefengtech.com",
      // test: "http://test.property.dubbo.liefengtech.com",
      // prod: "https://prod.property.dubbo.liefengtech.com",
      dev: "http://test.o2o-gateway.dubbo.liefengtech.com",
      test: "http://test.o2o-gateway.dubbo.liefengtech.com",
      prod: "https://gateway.prod.liefengtech.com",
    }), // 测试
    ws: false,
    changeOrigin: true,
    // secure: true, // https协议才设置
    pathRewrite: {
      "^/baseparty": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/datamsg": {
    target: domain({
      // dev: "http://suiyue2.test.liefengtech.com:83/suiyue",
      // test: "http://api.oldpeople.test.liefengtech.com",
      // prod: "https://api.oldpeople.prod.liefengtech.com",
      dev: "http://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople",
      test: "http://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople",
      prod: "https://api.gateway.prod.liefengtech.com/apps/oldpeople",
    }), // 字典数据
    ws: false,
    changeOrigin: true,
    // secure: true, // https协议才设置
    pathRewrite: {
      "^/datamsg": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/oss": {
    target: domain({
      dev: "http://liefeng.oss-cn-shenzhen.aliyuncs.com",
      test: "http://liefeng.oss-cn-shenzhen.aliyuncs.com",
      prod: "https://liefeng.oss-cn-shenzhen.aliyuncs.com",
    }), // 字典数据
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/oss": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/osscode": {   //阿里云加密
    target: domain({
      dev: "http://liefeng-suiyue.oss-cn-shenzhen.aliyuncs.com",
      test: "http://liefeng-suiyue.oss-cn-shenzhen.aliyuncs.com",
      prod: "https://liefeng-suiyue.oss-cn-shenzhen.aliyuncs.com",
    }), // 字典数据
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/osscode": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/voteapi": {
    target: domain({
      // dev: "http://suiyue2.test.liefengtech.com:83/suiyue",
      // test: "http://api.oldpeople.test.liefengtech.com",
      // prod: "https://api.oldpeople.prod.liefengtech.com",
      dev: 'http://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople',
      test: 'http://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople',
      prod: 'https://api.gateway.prod.liefengtech.com/apps/oldpeople',
    }), // 字典数据
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/voteapi": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/basicgong": {
    target: domain({
      // dev: "http://suiyue2.test.liefengtech.com:83/base",
      // test: "http://test.basic.dubbo.liefengtech.com",
      // prod: "https://basic.prod.liefengtech.com",
      dev: "http://liefeng-gateway.test.gongxiangplat.com/apps/basic",
      test: "http://liefeng-gateway.test.gongxiangplat.com/apps/basic",
      prod: "https://api.gateway.prod.liefengtech.com/apps/basic",
    }), // 字典数据
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/basic": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/statistic": {    //统计报表 404
    target: domain({
      // dev: "http://suiyue2.test.liefengtech.com:83/sy-manage/sy-manage",
      // test: "http://api.sy-manage.test.gongxiangplat.com/sy-manage",
      // prod: "https://sy-manage.prod.liefengtech.com/sy-manage",
      dev: "http://liefeng-gateway.test.gongxiangplat.com/sy-manage/sy-manage",
      test: "http://liefeng-gateway.test.gongxiangplat.com/sy-manage/sy-manage",
      prod: "https://api.gateway.prod.liefengtech.com",
    }), // 字典数据
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/statistic": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/archives": {    //台账档案  404
    target: domain({
      // dev: "http://suiyue2.test.liefengtech.com:83/sy-archives/sy-archives",
      // test: "http://api.sy-archives.test.gongxiangplat.com/sy-archives",
      // prod: "https://sy-archives.prod.liefengtech.com/sy-archives",
      dev: "http://liefeng-gateway.test.gongxiangplat.com/sy-archives/sy-archives",
      test: "http://liefeng-gateway.test.gongxiangplat.com/sy-archives/sy-archives",
      prod: "https://api.gateway.prod.liefengtech.com/apps/sy-archives",
    }), // 字典数据
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/archives": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/pension": {    //养老评估
    target: domain({
      // dev: "http://suiyue2.test.liefengtech.com:83/sy-elderlyassess/sy-elderlyassess",
      // test: "http://api.sy-elderly-assess.test.liefengtech.com/sy-elderlyassess",
      // prod: "https://api.sy-elderly-assess.prod.liefengtech.com/sy-elderlyassess",
      dev: "http://liefeng-gateway.test.gongxiangplat.com/apps/sy-elderlyassess",
      test: "http://liefeng-gateway.test.gongxiangplat.com/apps/sy-elderlyassess",
      prod: "https://api.gateway.prod.liefengtech.com/apps/sy-elderlyassess",
    }), // 字典数据
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/pension": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  "/o2ogong": {    //养老评估
    target: domain({
      dev: "https://liefeng-gateway.test.gongxiangplat.com",
      test: "https://liefeng-gateway.test.gongxiangplat.com",
      prod: "https://api.gateway.prod.liefengtech.com",
    }), // 字典数据
    ws: false,
    changeOrigin: true,
    secure: true, // https协议才设置
    pathRewrite: {
      "^/o2o": "/", // 这种接口配置出来     http://XX.XX.XX.XX:8083/api/login
    },
  },
  '/gateway': {
    target: domain({
      // dev: 'http://api.gateway.liefeng.commontech.xyz:81',
      // test: 'http://api.gateway.liefeng.commontech.xyz:81',
      dev: 'http://liefeng-gateway.test.gongxiangplat.com',
      test: 'http://liefeng-gateway.test.gongxiangplat.com',
      prod: 'https://api.gateway.prod.liefengtech.com',
    }),
    ws: false,
    changeOrigin: true,
    secure: true,
    pathRewrite: {
      "^/gateway": "/",
    }
  },

  '/device': {
    target: domain({
      // dev: 'http://api.device.test.liefengtech.com',
      // test: 'http://api.device.test.liefengtech.com',
      // prod: 'https://api.device.prod.liefengtech.com',
      dev: 'http://liefeng-gateway.test.gongxiangplat.com/apps/device',
      test: 'http://liefeng-gateway.test.gongxiangplat.com/apps/device',
      prod: 'https://api.gateway.prod.liefengtech.com/apps/device',
    }),
    ws: false,
    changeOrigin: true,
    secure: true,
    pathRewrite: {
      "^/device": "/",
    }
  },
  '/orgzz': {
    target: domain({
      // dev: 'http://suiyue2.test.liefengtech.com:83/org/org',
      // test: 'http://api.org.test.gongxiangplat.com/org',
      // prod: 'https://api.org.prod.gongxiangplat.com/org'
      dev: 'http://liefeng-gateway.test.gongxiangplat.com/org',
      test: 'http://liefeng-gateway.test.gongxiangplat.com/org',
      prod: 'https://api.gateway.prod.liefengtech.com/apps/org/org'
    }),
    ws: false,
    changeOrigin: true,
    secure: true,
    pathRewrite: {
      "^/orgzz": "/",
    }
  },
  '/dictlist': {
    target: domain({
      // dev: 'http://suiyue2.test.liefengtech.com:83/lf-common/lf-common',
      // test: 'http://api.lfcommon.test.liefengtech.com',
      // prod: 'https://api.lfcommon.prod.liefengtech.com',
      dev: 'http://liefeng-gateway.test.gongxiangplat.com',
      test: 'http://liefeng-gateway.test.gongxiangplat.com',
      prod: 'https://api.gateway.prod.liefengtech.com',
    }),
    ws: false,
    changeOrigin: true,
    secure: true,
    pathRewrite: {
      "^/dictlist": "/",
    }
  },
  '/custom': {
    target: domain({
      dev: 'https://suiyue.h5.test.liefengtech.com',
      test: 'https://suiyue.h5.test.liefengtech.com',
      prod: 'https://h5.suiyue.liefengtech.com',
    }),
    ws: false,
    changeOrigin: true,
    secure: true,
    pathRewrite: {
      "^/custom": "/",
    }
  },
  '/info': {
    target: domain({
      dev: 'http://liefeng-gateway.test.gongxiangplat.com/syinfopublish',
      test: 'http://liefeng-gateway.test.gongxiangplat.com/syinfopublish',
      prod: 'https://api.gateway.prod.liefengtech.com/syinfopublish',
    }),
    ws: false,
    changeOrigin: true,
    secure: true,
    pathRewrite: {
      "^/info": "/",
    }
  },
}
