import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baiduAk:"E6Gfy5LUBkyqxWwygjg31YkW4SPjXqvi",//百度AK
    accessToken: "",
    //用于识别新增和修改党员信息
    addChange: true,
    portal:{},

    // 社区架构管理-建设总览->民情档案修改回跳参数
    buildObj:{},
    buildChild:{},
    buildHouse:'',

    // 网格管理-建设总览->民情档案修改回跳参数
    gridCommunityCode:'',
    gridNums:0,
    gridItem:{},
    gridItems:{},
    gridHouseCode:'',

  },
  mutations: {
    //修改addChange
    toggleAddChange(state,payload) {
      state.addChange = payload;
    },
    portalInfo(state,payload){
      state.portal = payload
    },
    changeBuildObj(state,payload){
      state.buildObj = payload
    },
    changeBuildChild(state,payload){
      state.buildChild = payload
    },
    changeBuildHouse(state,payload){
      state.buildHouse = payload
    },
    changeGridCommunityCode(state,payload){
      state.gridCommunityCode = payload
    },
    changeGridNums(state,payload){
      state.gridNums = payload
    },
    changeGridItem(state,payload){
      state.gridItem = payload
    },
    changeGridItems(state,payload){
      state.gridItems = payload
    },
    changeGridHouseCode(state,payload){
      state.gridHouseCode = payload
    }
  },
  actions: {},
  modules: {},
});
