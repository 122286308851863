import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//解决vueRouter 跳转相同路由报错重写push方法，添加catch
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

var routes = () => {
  let routesConfig=[];
  let paths=[];

  paths=require("@/router/path.js").default;

  paths.map(item=>{
    routesConfig.push({
      path: item.path,
      name: item.path.substring(1),
      component: () => import(/*webpackChunkName: "liefeng-[request]" */'@/'+item.file),
    });
  });



  // routesConfig.push(
  //   {
  //     path: "/", // 默认页面重定向到主页
  //     redirect: "/", // 重定向 
  //   }
  //   );
  
    routesConfig.push({
      path: "/noRight",
      name: "noRight",
      component: () => import(/*webpackChunkName: "noright" */'@/portal/noright.vue'),
    });
    
  
    return routesConfig;
};

const router = new VueRouter({
  mode: "hash",//hash则带#,history
  base: process.env.BASE_URL,
  routes: routes(),
});
router.beforeEach((to, from, next) => {
  next();
});


export default router;
